<template>
  <!-- Widget Selection -->
  <div class="h-100 widget-types">
    <!-- Header -->
    <div class="widget-types__heading">
      <div class="d-flex align-items-center mb-4">
        <img src="/img/icons/vector.svg"/>
        <div class="font-size-18 ml-2">
          {{ staticText.addWidget }}
        </div>
      </div>
      <div class="input-wrapper mb-2">
        <b-form-input v-model="widgetTypeFilter"
                      :placeholder="staticText.searchPlaceholderLabel"
                      class="pr-5"
        ></b-form-input>
        <span class="search-icon">
          <img src="/img/icons/search-icon.svg"/>
        </span>
        <span class="input-label">
          {{ staticText.search }}
        </span>
      </div>
    </div>

    <!-- Widget type display -->
    <div class="widget-types__body">
      <div v-for="(widget, index) in shownWidgetTypes"
           :key="index">
        <b-btn
          class="widget-types__item"
          v-b-hover="isHovered => handleHover(isHovered, widget.title)"
          @click="setWidgetToConfigure(widget)"
        >
          <div class="row text-left">
            <div class="col border-right py-auto">
              <div class="row mx-auto pb-3"> {{ widget.title }} </div>
              <div class="widget-types__item--thumbnail-div">
                <b-btn v-if="hoveredWidget === widget.title"
                       class="py-2 my-1"
                       variant="primary"
                >
                  {{staticText.select}}
                </b-btn>
                <img v-else
                     style="max-width: 150px"
                     :src="widget.thumbnail"
                />
              </div>
            </div>
            <div class="col-7 px-3 ">
              <p class="text-black-50 m-0">
                {{widget.description}}
              </p>
            </div>
          </div>
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapGetters, mapMutations } from "vuex"

export default {
  name: "WidgetCreationSidebar",
  data () {
    return {
      axios,
      staticTextDefault: {
        addWidget: "Add widget",
        searchPlaceholderLabel: "Search for widgets",
        search: "Search",
        select: "Select"
      },
      widgetTypeFilter: "",
      previewChart: null,
      hoveredWidget: ""
    }
  },
  computed: {
    ...mapGetters({
      currentPageId: "dashboardStore/getCurrentPageId",
      defaultWidgets: "dashboardStore/getDefaultWidgets"
    }),
    transformedFilter () {
      return new RegExp(this.widgetTypeFilter, "i")
    },
    isSearching () {
      return !!this.widgetTypeFilter && this.widgetTypeFilter.length > 0
    },
    shownWidgetTypes () {
      let widgetTypes = Object.values(this.defaultWidgets).filter(widgetType => !widgetType.deprecated)
      if (this.isSearching) {
        widgetTypes = widgetTypes.filter(widgetType => this.transformedFilter.test(widgetType.title))
      }
      return widgetTypes
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    ...mapMutations({
      setWidgetToConfigure: "dashboardStore/setWidgetToConfigure"
    }),
    handleHover (isHovered, widget) {
      this.hoveredWidget = isHovered ? widget : ""
    }
  }
}
</script>

<style scoped lang="scss">

.widget-types {
  height: 100%;
  overflow: hidden;
  &__heading {
    height: 120px;
    padding-right: 8px;
    margin-bottom: 10px;
  }
  &__body {
    padding-right: 6px;
    height: calc(100% - 120px);
    overflow-y: auto;
  }
  &__item {
    margin-bottom: 16px;
    padding: 16px;
    width: 100%;
    border-radius: 20px;
    background: $white;
    &:hover {
      background: rgba(42, 45, 82, 0.03);
    }
    &--thumbnail-div {
      height: 56px;
    }
  }
}
.search-icon {
  color: $slate04;
  position: absolute;
  top: 18px;
  right: 20px;
}
</style>
